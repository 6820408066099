import React, { useState, useEffect } from "react";

import "./styles.scss";

const CountdownTimer = ({ targetDate }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      const remainingDays = Math.floor(difference / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const remainingMinutes = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const remainingSeconds = Math.floor((difference % (1000 * 60)) / 1000);

      setDays(String(remainingDays).padStart(2, "0"));
      setHours(String(remainingHours).padStart(2, "0"));
      setMinutes(String(remainingMinutes).padStart(2, "0"));
      setSeconds(String(remainingSeconds).padStart(2, "0"));

      if (difference < 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <div className="Countdown">
      <div className="Countdown__item">
        <span className="Countdown__value">{days}</span>
        <span className="Countdown__label">Días</span>
      </div>
      <div className="Countdown__item">
        <span className="Countdown__value">{hours}</span>
        <span className="Countdown__label">Horas</span>
      </div>
      <div className="Countdown__item">
        <span className="Countdown__value">{minutes}</span>
        <span className="Countdown__label">Minutos</span>
      </div>
      <div className="Countdown__item">
        <span className="Countdown__value">{seconds}</span>
        <span className="Countdown__label">Segundos</span>
      </div>
    </div>
  );
};

export default CountdownTimer;
