import logo from "../../assets/images/logo_blue.svg";
import buttonplus from "./images/plus.svg";

import "./styles.scss";

const Home = () => {
  return (
    <div className="Home">
      <div className="Home__banner">
        <div className="Home__banner__title">
          Bienvenidos a{" "}
          <div className="Home__banner__title__logo">
            <img src={logo} />
          </div>
        </div>
        <div className="Home__banner__subtitle">
          El lugar donde todos somos influencers
        </div>
      </div>
      <div className="Home__section1">
        <div className="Home__card Home__card--1">
          <div className="Home__card--1__image" />
          <div className="Home__card--1__title">
            <p>
              ¿Estás listo para ganar premios mientras disfrutas de tus planes
              favoritos?
            </p>
            <p>
              Con Wubalu,
              <br /> es fácil y divertido.
            </p>
          </div>
        </div>
        <a
          className="Home__card Home__card--2"
          href="https://restaurants.wubalu.com"
        >
          <div className="Home__card--2__yellow">
            <div className="Home__card--2__plus">
              <img src={buttonplus} />
            </div>
            <div className="Home__card--2__title">
              ¿Eres un restaurante?
              <br />
              Entra aquí para concenrnos
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Home;
