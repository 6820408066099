import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useAuth from "../../../models/auth/useAuth";
import IgIcon from "../../../assets/images/ig.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { gaEvent } from "../../../utils/gaHelper";

import "./styles.scss";

const VerifyInstagram = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { verifyIgAccount } = useAuth();
  const { me } = useAuth();
  const [restaurantSlug, setRestaurantSlug] = useState();

  const handleInstagramResponse = async () => {
    try {
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const slug = state || location.state?.restaurant;
      setRestaurantSlug(slug);
      searchParams.delete("code");
      searchParams.delete("state");
      setSearchParams(searchParams);
      await verifyIgAccount(code, window.location.href);
      await me();
      toast.success("Gracias, ya puedes subir la captura de pantalla");
      navigate(`/upload-story/${slug}/form`);
    } catch (e) {
      toast.error("Ups, ha ocurrido un error.");
    }
  };

  const handleInstagramError = () => {
    // Controlar todos los errores por tipo.
    const state = searchParams.get("state");
    setRestaurantSlug(state || location.state?.restaurant);
    setSearchParams({});
    toast.error("Ups, ha ocurrido un error. Vuelve a intentarlo.");
  };

  const igLogin = () => {
    const url = `https://api.instagram.com/oauth/authorize?client_id=700017672206118&redirect_uri=${window.location.href}&state=${restaurantSlug}&scope=user_profile&response_type=code`;
    window.location.replace(url);
  };

  useEffect(() => {
    searchParams.has("code") && handleInstagramResponse();
  }, [searchParams.has("code")]);

  useEffect(() => {
    searchParams.has("error") && handleInstagramError();
  }, [searchParams.has("error")]);

  useEffect(() => {
    location.state?.restaurant && setRestaurantSlug(location.state.restaurant);
  }, [location.state?.restaurant]);

  useEffect(() => {
    gaEvent({ name: "us_verify_ig" });
  }, []);

  return (
    <Dialog open={true} className="IgModal">
      <DialogTitle>Necesitamos verificar tu cuenta de Instagram</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Este proceso solo es necesario porque ha ocurrido un error al
          verificar tu cuenta de Instagram automaticamente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={igLogin}>
          <img src={IgIcon} />
          <span>Verificar Instagram</span>
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default VerifyInstagram;
