import { useState } from "react";
import useFetch from "../../utils/useFetch";

const useStories = () => {
  const { post, get } = useFetch();
  const [detail, setDetail] = useState();
  const endpoint = "stories";

  const create = async (body) => {
    return await post({
      endpoint: `${endpoint}/public-create/`,
      body,
      isMultipart: true,
      preventLoader: true,
    });
  };

  const fetchDetail = async (id) => {
    const response = await get({
      endpoint: `${endpoint}/${id}`,
    });
    setDetail(response);
  };

  const fetchStoryStatus = async (id) => {
    const response = await get({
      endpoint: `${endpoint}/${id}/status`,
      preventLoader: true,
    });
    setDetail({ ...detail, status: response.status });
  };

  return {
    createStory: create,
    fetchStory: fetchDetail,
    fetchStoryStatus,
    story: detail,
  };
};

export default useStories;
