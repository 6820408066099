import logo from "../../assets/images/logo_blue.svg";

import "./styles.scss";

const Header = () => {
  return (
    <div className="Header">
      <div className="Header__logo">
        <img src={logo} />
      </div>
    </div>
  );
};

export default Header;
