import { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import useRestaurants from "../../models/restaurants/useRestaurants";
import Landing from ".";
import USForm from "./Form";
import AuthRoute from "../../containers/AuthRoute";
import USQR from "./QR";
import USThanks from "./Thanks";
import USError from "./Error";
import useAuth from "../../models/auth/useAuth";
import USAlreadyParticipating from "./AlreadyParticipating";
import useCampaigns from "../../models/campaigns/useCampaigns";

const CampaignRouter = () => {
  const { campaignType, campaignId } = useParams();
  const { campaign, fetchCampaign } = useCampaigns();

  useEffect(() => {
    fetchCampaign(campaignId, campaignType);
  }, [campaignId, campaignType]);
  return (
    campaign.id && (
      <Routes>
        <Route path="/form" element={<USForm />} />
        <Route path="/story-qr/:storyId" element={<USQR />} />
        <Route path="/thanks" element={<USThanks />} />
        <Route path="/error" element={<USError />} />
        <Route
          path="/already-participating"
          element={<USAlreadyParticipating />}
        />
      </Routes>
    )
  );
};

const UploadStoryRouter = () => {
  const { restaurantId } = useParams();
  const { fetchRestaurant, clearRestaurant } = useRestaurants();

  useEffect(() => {
    fetchRestaurant(restaurantId);
    return () => {
      clearRestaurant();
    };
  }, [restaurantId]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/:campaignType/:campaignId/*" element={<CampaignRouter />} />
    </Routes>
  );
};

export default UploadStoryRouter;
