import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaderCount: 0,
  loaderTitle: "",
  loaderText: "",
};

export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showLoader: (state, action) => {
      state.loaderCount += 1;
      state.loaderText = action.payload?.loaderText;
      state.loaderTitle = action.payload?.loaderTitle;
    },
    hideLoader: (state) => {
      state.loaderCount -= 1;
      state.loaderText = "";
      state.loaderTitle = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoader, hideLoader } = slice.actions;

export default slice.reducer;
