import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CountdownTimer from "../../../components/Countdown";
import { gaEvent } from "../../../utils/gaHelper";

import "./styles.scss";

const USAlreadyParticipating = () => {
  const location = useLocation();
  const date = location.state.participationDate;

  useEffect(() => {
    gaEvent({ name: "us_already_participating" });
  }, []);

  return (
    <div className="USAlreadyParticipating">
      <div className="USAlreadyParticipating__main">
        <div className="title">Podrás volver a participar dentro de:</div>
        <div>
          <CountdownTimer targetDate={new Date(date)} />
        </div>
      </div>
      <div className="USAlreadyParticipating__text">
        Cada restaurante elige la frecuencia con la que se puede participar.
      </div>
    </div>
  );
};

export default USAlreadyParticipating;
