import { Typography } from "@mui/material";

import "./styles.scss";

const Footer = () => {
  return (
    <div className="Footer">© 2024 Wubalu. Todos los derechos reservados.</div>
  );
};

export default Footer;
