import { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../Loader";
import usePageTracking from "../../utils/usePageTracking";

import logo from "../../assets/images/logo_white.svg";

import "react-sliding-pane/dist/react-sliding-pane.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

const Layout = () => {
  const { pathname } = useLocation();
  const contentRef = useRef();
  usePageTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const setDocHeight = () => {
    contentRef.current.style.minHeight = `${window.innerHeight}px`;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setDocHeight);
      window.addEventListener("orientationchange", setDocHeight);
      setDocHeight();
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", setDocHeight);
        window.removeEventListener("orientationchange", setDocHeight);
      }
    };
  }, []);

  return (
    <div className="Layout">
      <div className="Layout__content" ref={contentRef}>
        <div className="Layout__logo">
          <img src={logo} />
        </div>
        <Outlet />
      </div>
      <ToastContainer />
      <Loader />
    </div>
  );
};

export default Layout;
