import { useDispatch, useSelector } from "react-redux";

import { showLoader, hideLoader } from "./store";

const useUi = () => {
  const { loaderCount, loaderText, loaderTitle } = useSelector(
    (state) => state.ui
  );
  const dispatch = useDispatch();

  return {
    showLoader: (payload) => dispatch(showLoader(payload)),
    hideLoader: () => dispatch(hideLoader()),
    isLoaderVisible: loaderCount > 0,
    loaderText,
    loaderTitle,
  };
};

export default useUi;
