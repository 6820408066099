const DataRemoval = () => {
  return (
    <div>
      <h2>Eliminación de Datos de Usuario</h2>
      <p>
        Si deseas eliminar tus datos de usuario de Wubalu, por favor sigue estos
        pasos:
      </p>
      <ol>
        <li>
          Envía un correo electrónico a{" "}
          <a href="hello@wubalu.com">hello@wubalu.com</a> desde la dirección
          asociada a tu cuenta.
        </li>
        <li>
          Indica claramente en el asunto del correo "Eliminación de Datos de
          Usuario".
        </li>
        <li>
          Nuestro equipo procesará tu solicitud y eliminará tus datos en un
          plazo de 15 días.
        </li>
      </ol>
      <p>
        Gracias por utilizar Wubalu. Si tienes alguna pregunta adicional o
        necesitas ayuda, no dudes en contactarnos.
      </p>
    </div>
  );
};

export default DataRemoval;
