const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Política de Privacidad</h2>
      <p>
        En Wubalu, respetamos tu privacidad y nos comprometemos a proteger tus
        datos personales. Esta política de privacidad describe cómo recopilamos,
        utilizamos y protegemos la información que nos proporcionas al utilizar
        nuestra aplicación.
      </p>
      <ul>
        <li>
          <strong>Datos recopilados:</strong> Solo recopilamos y almacenamos el
          nombre de usuario de Instagram proporcionado por el usuario.
        </li>
        <li>
          <strong>Uso de datos:</strong> Los datos recopilados se utilizan
          únicamente para el funcionamiento de la aplicación y no se comparten
          con terceros.
        </li>
        <li>
          <strong>Seguridad:</strong> Implementamos medidas de seguridad para
          proteger tus datos contra accesos no autorizados o uso indebido.
        </li>
        <li>
          <strong>Eliminación de datos:</strong> Los usuarios pueden solicitar
          la eliminación de sus datos enviando un correo electrónico a
          hello@wubalu.com desde la dirección asociada a su cuenta.
        </li>
        <li>
          <strong>Cookies:</strong> Wubalu no utiliza cookies ni tecnologías
          similares para rastrear la actividad del usuario.
        </li>
        <li>
          <strong>Cambios en la política:</strong> Nos reservamos el derecho de
          actualizar esta política de privacidad en cualquier momento. Se
          notificará a los usuarios sobre cualquier cambio significativo.
        </li>
        <li>
          <strong>Contacto:</strong> Si tienes alguna pregunta o inquietud sobre
          nuestra política de privacidad, contáctanos a través de
          hello@wubalu.com.
        </li>
      </ul>
      <p>
        Al utilizar Wubalu, aceptas nuestra política de privacidad. Si no estás
        de acuerdo con esta política, por favor, abstente de utilizar nuestra
        aplicación.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
