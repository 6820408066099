import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import Layout from "./containers/Layout";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import useAuth from "./models/auth/useAuth";
import UploadStoryRouter from "./pages/UploadStory/Router";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsOfService from "./pages/legal/TermsOfService";
import DataRemoval from "./pages/legal/DataRemoval";
import VerifyInstagram from "./pages/UploadStory/VerifyInstagram";
import Home from "./pages/Home";
import AdminLayout from "./containers/AdminLayout";
import "./styles.scss";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <AdminLayout>
            <Home />
          </AdminLayout>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/delete-account-data" element={<DataRemoval />} />
        <Route path="/verify-instagram" element={<VerifyInstagram />} />
        <Route
          path="/upload-story/:restaurantId/*"
          element={<UploadStoryRouter />}
        />
      </Route>
    </>
  )
);

function App() {
  const { isAuthenticated, me } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) me();
  }, []);

  return (
    <GoogleOAuthProvider clientId="799471198822-i04f0ncrnkavdpd6fhv3h3ge0jp7v40o.apps.googleusercontent.com">
      <RouterProvider router={router} />
    </GoogleOAuthProvider>
  );
}

export default App;
