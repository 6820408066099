import { Link, useNavigate } from "react-router-dom";
import useRestaurants from "../../models/restaurants/useRestaurants";
import handIcon from "../../assets/images/hand1.png";
import { useEffect } from "react";
import { gaEvent } from "../../utils/gaHelper";

import "./styles.scss";

const USLanding = () => {
  const navigate = useNavigate();
  const { restaurant } = useRestaurants();

  const countCampaigns = () =>
    restaurant.brand_campaigns.length + restaurant.account_campaigns.length;

  useEffect(() => {
    if (restaurant.id) {
      if (countCampaigns() === 1) {
        const campaignType =
          restaurant.brand_campaigns.length === 1 ? "brand" : "account";
        const campaignId =
          campaignType === "brand"
            ? restaurant.brand_campaigns[0].id
            : restaurant.account_campaigns[0].id;
        navigate(`${campaignType}/${campaignId}/form`);
      }
    }
  }, [restaurant]);

  useEffect(() => {
    gaEvent({ name: "us_landing" });
  }, []);

  return (
    restaurant.id && (
      <div className="USLanding">
        {restaurant.is_active ? (
          <>
            <div className="USLanding__main">
              <div className="USLanding__image">
                <img src={handIcon} />
              </div>
              <div className="USLanding__title title">
                Elige tu <span> consumición gratuita</span>
              </div>
              <div className="USLanding__text">
                Selecciona el regalo que más te guste y a continuación te
                indicamos los pasos a seguir.
              </div>
            </div>
            <div>
              {(restaurant.account_campaigns || []).map((item) => (
                <Link
                  to={`account/${item.id}/form`}
                  key={item.id}
                  className="USLanding__prize"
                >
                  <div>{item.influencer_prize}</div>
                </Link>
              ))}
              {(restaurant.brand_campaigns || []).map((item) => (
                <Link
                  to={`brand/${item.id}/form`}
                  key={item.id}
                  className="USLanding__prize"
                >
                  <div>{item.influencer_prize}</div>
                </Link>
              ))}
            </div>
            {/* <div className="USLanding__button">
              <Link to="form">
                <button className="button">¡Vamos!</button>
              </Link>
              <div className="USLanding__terms">
                Continuando, aceptas los{" "}
                <a href="https://www.wubalu.com/terms" target="_blank">
                  términos y condiciones
                </a>
              </div>
            </div> */}
          </>
        ) : (
          <div>
            <div>Restaurante desactivado</div>
          </div>
        )}
      </div>
    )
  );
};

export default USLanding;
