import { useEffect } from "react";

import useRestaurants from "../../../models/restaurants/useRestaurants";
import thanks from "../../../assets/images/thanks.svg";
import ok from "../../../assets/images/ok.svg";
import { gaEvent } from "../../../utils/gaHelper";

import "./styles.scss";

const USThanks = () => {
  const { restaurant } = useRestaurants();

  useEffect(() => {
    gaEvent({ name: "us_thanks" });
  }, []);

  return (
    <div className="USThanks">
      <div className="USThanks__layout">
        <div className="USThanks__thanks-img">
          <img src={thanks} />
        </div>
        <div className="USThanks__content">
          <div className="title">
            Gracias,
            <br />
            ¡disfruta de tu premio!
          </div>
          <div>
            Podrás volver a participar en {restaurant.participation_rate} días
          </div>
        </div>
        <div className="USThanks__ok-img">
          <img src={ok} />
        </div>
      </div>
    </div>
  );
};

export default USThanks;
