import { Link } from "react-router-dom";
import useRestaurants from "../../../models/restaurants/useRestaurants";

import ko from "../../../assets/images/ko.svg";

import "./styles.scss";
import { useEffect, useRef } from "react";
import { gaEvent } from "../../../utils/gaHelper";

const USError = () => {
  const contentRef = useRef();
  const setDocHeight = () => {
    contentRef.current.style.minHeight = `${window.innerHeight}px`;
  };

  useEffect(() => {
    gaEvent({ name: "us_rejected" });
    if (typeof window !== "undefined") {
      window.addEventListener("resize", setDocHeight);
      window.addEventListener("orientationchange", setDocHeight);
      setDocHeight();
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", setDocHeight);
        window.removeEventListener("orientationchange", setDocHeight);
      }
    };
  }, []);

  return (
    <div className="USError">
      <div className="USError__layout" ref={contentRef}>
        <div>
          <div className="USError__thanks-img">
            <img src={ko} />
          </div>
          <div className="title">Upsss!</div>
          <div>Algo ha ido mal, inténtalo de nuevo.</div>
        </div>
        <div>
          <Link to="../form">
            <button className="button">Volver a intentarlo</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default USError;
