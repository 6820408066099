import { ToastContainer } from "react-toastify";
import Footer from "../Footer";
import Header from "../Header";
import Loader from "../Loader";

import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

const AdminLayout = ({ children }) => {
  return (
    <>
      <div className="AdminLayout">
        <div className="AdminLayout__header">
          <Header />
        </div>
        <div className="AdminLayout__main">{children}</div>
        <div className="AdminLayout__footer">
          <Footer />
        </div>
      </div>
      <Loader />
      <ToastContainer />
    </>
  );
};

export default AdminLayout;
