import qs from "query-string";
import useUi from "../models/ui/useUi";

const stringify = qs.stringify;

const useFetch = () => {
  const { showLoader, hideLoader } = useUi();
  const baseUrl = process.env.REACT_APP_API;

  const getHeaders = (isPrivate, isMultipart) => {
    const headers = isMultipart
      ? {}
      : {
          "Content-Type": "application/json",
          Accept: "application/json",
        };
    if (!isPrivate) {
      return headers;
    }
    return {
      ...headers,
      Authorization: `Bearer ${localStorage.wubalu_access_token}`,
    };
  };

  const responseHandler = async (response) => {
    try {
      if (!response.ok) {
        throw response;
      } else {
        const res = await response.json();
        return res;
      }
    } catch (e) {
      throw response;
    }
  };

  const get = async ({ endpoint, params, isPrivate, preventLoader }) => {
    const url = `${baseUrl}/${endpoint}${
      params ? `?${stringify(params)}` : ""
    }`;
    !preventLoader && showLoader();
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: getHeaders(isPrivate),
      });
      const parsedResponse = await responseHandler(response);
      return parsedResponse;
    } catch (e) {
      throw e;
    } finally {
      !preventLoader && hideLoader();
    }
  };

  const post = async ({
    endpoint,
    body,
    isPrivate,
    isMultipart,
    preventLoader,
  }) => {
    const url = `${baseUrl}/${endpoint}`;
    !preventLoader && showLoader();
    try {
      const response = await fetch(url, {
        body: isMultipart ? body : JSON.stringify(body),
        method: "POST",
        headers: getHeaders(isPrivate, isMultipart),
      });
      const parsedResponse = await responseHandler(response);
      return parsedResponse;
    } catch (e) {
      throw e;
    } finally {
      !preventLoader && hideLoader();
    }
  };

  const put = async ({ endpoint, id, body, isPrivate, isMultipart }) => {
    const url = `${baseUrl}/${endpoint}/${id}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: isMultipart ? body : JSON.stringify(body),
        method: "PUT",
        headers: getHeaders(isPrivate),
      });
      const parsedResponse = await responseHandler(response);
      return parsedResponse;
    } catch (e) {
      throw e;
    } finally {
      hideLoader();
    }
  };

  const patch = async ({ endpoint, id, body, isPrivate, isMultipart }) => {
    const url = `${baseUrl}/${endpoint}/${id}`;
    showLoader();
    try {
      const response = await fetch(url, {
        body: isMultipart ? body : JSON.stringify(body),
        method: "PATCH",
        headers: getHeaders(isPrivate, isMultipart),
      });
      const parsedResponse = await responseHandler(response);
      return parsedResponse;
    } catch (e) {
      throw e;
    } finally {
      hideLoader();
    }
  };

  const del = async ({ endpoint, id, isPrivate = true }) => {
    const url = `${baseUrl}/${endpoint}/${id}`;
    showLoader();
    try {
      return await fetch(url, {
        method: "DELETE",
        headers: getHeaders(isPrivate),
      });
    } catch (e) {
      throw e;
    } finally {
      hideLoader();
    }
  };

  return {
    get,
    post,
    put,
    patch,
    del,
  };
};

export default useFetch;
