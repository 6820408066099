import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isLoaded: false,
  user: {},
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
      state.isLoaded = true;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setIsLoaded, userLogout } = slice.actions;

export default slice.reducer;
