import React from "react";
import ReactDOM from "react-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { store } from "./redux/store";
import "@fontsource/open-sans";
import "@fontsource/open-sans/700.css"; // Specify weight
import "@fontsource/open-sans/600.css"; // Specify weight

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    <SpeedInsights />
    <Analytics />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals(sendToVercelAnalytics);
