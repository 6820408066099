import { useSelector, useDispatch } from "react-redux";
import useFetch from "../../utils/useFetch";
import { setDetail, clearDetail } from "./store";

const useCampaigns = () => {
  const { detail } = useSelector((state) => state.campaigns);
  const dispatch = useDispatch();
  const { get } = useFetch();

  const getEndpoint = (type) => {
    if (type === "account") return "account-campaigns";
    return "brand-campaigns";
  };

  const fetchDetail = async (id, type) => {
    const endpoint = getEndpoint(type);
    const response = await get({ endpoint: `${endpoint}/${id}` });
    dispatch(setDetail(response));
  };

  return {
    campaign: detail,
    fetchCampaign: fetchDetail,
    clearCampaign: () => dispatch(clearDetail()),
  };
};

export default useCampaigns;
